/* ChooseKollel.css */
.kollel-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100%;
    background-color: #ffffff; /* Match background color with login page */
    padding: 20px; /* Add padding for better spacing */
}

.kollel-title {
    font-size: 2rem; /* Match font size with login page */
    margin-bottom: 10px;
    color: #333; /* Dark text color */
}

.kollel-subtitle {
    font-size: 1.2rem; /* Match font size with login page */
    margin-bottom: 20px;
    color: #666; /* Lighter text color */
}

.kollel-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.kollel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px; /* Add some spacing between items */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: transform 0.3s; /* Smooth transition for hover effect */
}

.kollel-item:hover {
    transform: scale(1.1); /* Scale up on hover */
}

.kollel-logo {
    width: 500px; /* Decreased width */
    height: 350px; /* Decreased height */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
    margin: 20px; /* Space between images */
}