/* styles.css */
.green-check {
    color: green !important;
    font-weight: bold !important;
    font-size: 18px;
    padding-top: 5px;
}

.red-cross {
    color: red !important;
    font-weight: bold !important;
    font-size: 18px !important;
    padding-top: 5px;
}

.table-container {
   width: 100% !important;
}