body {
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Varela Round', sans-serif,
  monospace;
}


.file-drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-drop-area.dragging {
  border-color: #007bff;
}

.file-drop-area label {
  display: block;
}

.file-drop-area input {
  display: none;
}

.icon-button {
  width: 30px;
  height: 30px;
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}

.ag-body-viewport ::-webkit-scrollbar-thumb {
  border-radius: 10px; /* roundness of the scrollbar thumb */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.input-lable {
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin-bottom: 8px
}

.ag-theme-alpine .ag-root-wrapper {
  z-index: 0;
  /* Set z-index to the lowest value */
}

.grid-container {
  position: relative;
  /* Ensure the grid container is positioned */
}

.form-title {
  width: "100%";
  text-align: "center";
  margin-bottom: "18px";
  margin-top: "0"
}