@font-face {
  font-family: 'Varela Round';
  src: url('../public/fonts/VarelaRound-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-font-family: 'Varela Round', sans-serif;
}

.App {
  text-align: center;
  font-family: var(--main-font-family), sans-serif;
}

body, .ag-theme-custom, [class*=ag-theme-] {
  font-family: var(--main-font-family), sans-serif !important;
}
